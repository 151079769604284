import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import AssetBrowser from 'common/components/AssetBrowser';
import { ApprovalActionButtons } from 'common/components/AssetBrowser/components';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import { APPROVALS } from 'common/core/approvals_enums';
import { RENDER_STYLE_LIST } from 'common/components/AssetBrowser/lib/constants';

class AssetBrowserApprovalsDemo extends Component {

  render() {
    const columns = [
      constants.COLUMN_TYPE,
      constants.COLUMN_NAME,
      constants.COLUMN_APPROVAL_SUBMITTED_AT,
      constants.COLUMN_OWNER,
      constants.COLUMN_APPROVAL_STATUS,
      constants.COLUMN_ACTIONS
    ];

    const tabs = {
      [constants.TAB_MY_QUEUE]: {
        props: {
          actionElement: ApprovalActionButtons,
          columns,
          baseFilters: {
            approvalStatus: [
              APPROVALS.STATUS.PENDING,
              APPROVALS.STATUS.APPROVED
            ],
            outcomeApplicationStatus: [
              APPROVALS.SUBMISSION_OUTCOME_STATUS.IN_PROGRESS,
              APPROVALS.SUBMISSION_OUTCOME_STATUS.NOT_APPLICABLE
            ]
          }
        }
      }
    };

    const assetBrowserProps = {
      approvalsSettingsLink: 'some link',
      assetSelector: false,
      closeOnSelect: false,
      columns,
      enableAssetInventoryActions: false,
      globalFilterDatasetUid: null,
      initialTab: constants.TAB_MY_QUEUE,
      onAssetSelected: (args) => alert(`selected! - args = ${JSON.stringify(args)}`),
      onClose: (args) => alert(`closed! - args = ${JSON.stringify(args)}`),
      openInNewTab: true,
      pageSize: 10,
      renderStyle: RENDER_STYLE_LIST,
      selectMode: true,
      showAssetCounts: true,
      showAwaitingApprovalFilter: true,
      showBackButton: false,
      showFilters: true,
      showGlobalFilterBarFilter: false,
      showHeader: false,
      showSearchField: true,
      tabs
    };

    return (
      <div>
        <div className="styleguide-example">
          <AssetBrowser {...assetBrowserProps} />
        </div>

        <h2 className="styleguide-header">Code example</h2>
        <div className="styleguide-code-example">
          <pre>
            {`
const assetBrowserProps = ${JSON.stringify(assetBrowserProps, null, 2)};

<AssetBrowser {...assetBrowserProps} />
            `}
          </pre>
        </div>
      </div>
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(AssetBrowserApprovalsDemo),
    document.getElementById('component-demo-approvals')
  );
});
